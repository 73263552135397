import React from 'react'

const Skillscard = ({name}) => {
    return(
        <div className="skillscard">
            <div className='compdet'>
                <h2>
                    {name}
                </h2>
            </div>

        </div>
    )
}

export default Skillscard